<template>
  <div class="customer-login-content">

    <h4 class="mb-0 w-full text-center font-normal lg:text-xl md:text-lg text-sm" style="letter-spacing:-0.5px;color:#666666;background:#F8F8F8;padding:22px 0;">Log in to your account</h4>

    <div class="onboard-login-content">
      <div v-if="error" class="error-message-container m-0 mb-8">
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.002 22C17.5248 22 22.002 17.5228 22.002 12C22.002 6.47715 17.5248 2 12.002 2C6.47911 2 2.00195 6.47715 2.00195 12C2.00195 17.5228 6.47911 22 12.002 22Z" stroke="#EB5757" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.002 8V12" stroke="#EB5757" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12.002 16H12.0147" stroke="#EB5757" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="flex flex-col items-start pl-4">
          <h5 class="text-base font-medium mb-2">{{ error.title }}</h5>
          <p class="text-sm mb-0 text-left " style="color:#666666;">{{ error.message }}</p>
        </div>
      </div>
      <div class="login-content">
        <div class="mb-5">
          <label for="email" class="text-base font-light">Email</label>
          <vs-input data-vv-validate-on="blur" v-validate="'required|email|min:3'" name="email" icon-no-border icon-pack="feather" v-model="email" class=" w-full"/>
          <div class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first("email") }}</div>
        </div>
        <label for="password" class="text-base font-light">Password</label>
        <vs-input data-vv-validate-on="blur" v-validate="'required'" name="password" icon-no-border icon-pack="feather" v-model="password" class="mb-3 w-full" 
          type="password" @keyup.enter="login"/>
        <div class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first("password") }}</div>
      </div>

      <span class="link-button text-left w-full light-blue pointer mb-0 underline" @click="forgotPassword"><u>Forgot password?</u></span>

      <vs-button v-round size="large" @click="login" class="w-full" :disabled="!validateForm" >Log in</vs-button>
    </div>

  </div>
</template>

<script>
import { Validator } from "vee-validate";

const dict = {
  custom: {
    email: {
      required: "Please enter your email"
    }
  }
};
Validator.localize("en", dict);

export default {
  props: {
    forgotPassword: { type: Function },
    loginOnboarding: { type: Function }
  },
  data() {
    return {
      email: "",
      password: "",
      error: ""
    };
  },
  methods: {
    async login() {
      this.error = await this.loginOnboarding(this.email, this.password);
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    }
  }
};
</script>
