<template>
  <div class="customer-login-content">
    <h4 class="mb-0 w-full text-center font-normal lg:text-xl md:text-lg text-sm" style="letter-spacing:-0.5px;color:#666666;background:#F8F8F8;padding:22px 0;">Reset your password</h4>
    <div class="onboard-login-content">
      <p class="text-base mb-8">
        Please enter your email address and we’ll send you a link to reset your password.
      </p>
      <label for="email">Email</label>
      <vs-input
        v-validate="'required|email|min:3'"
        name="email"
        type="email"
        icon-no-border
        icon-pack="feather"
        v-model="email"
        class="w-full"
      />
      <span class="flex mt-2"><p>Didn't get it?</p>&nbsp;<a class="text-blue-light underline" href="#" @click="recoverPassword" >Resend link</a></span>
      <div class="text-danger text-sm" v-show="errors.has('email')">
        {{ errors.first("email") }}
      </div>
      <div v-if="status" :class="`status-message-container success`">
        <img v-if="status" :src="checkCircle" width="20" height="20"/>
        <div class="mail-error">
          <h5 class="text-base mb-2 font-medium">{{ status.title }}</h5>
          <p class="text-sm">{{ status.message }}</p>
        </div>
      </div>


    <vs-button
      v-round
      @click="recoverPassword"
      class="w-full medium"
      :disabled="!validateForm"
      >Recover Password</vs-button
    >
    <vs-button v-round @click="backToLogin" class="w-full only-border-btn medium"
      >Back to login</vs-button
    ></div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
const alertCircle = require("@/assets/images/icons/alert-circle-red.png");
const checkCircle = require("@/assets/images/icons/check-circle.png");
const dict = {
  custom: {
    email: {
      required: "Please enter your email"
    }
  }
};
Validator.localize("en", dict);
export default {
  props: {
    forgotPassword: { type: Function },
    backToLogin: { type: Function }
  },
  data() {
    return {
      email: "",
      status: "",
      alertCircle: alertCircle,
      checkCircle: checkCircle
    };
  },
  methods: {
    async recoverPassword() {
      this.status = await this.forgotPassword(this.email);
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "";
    }
  }
};
</script>
