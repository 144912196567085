<template>
  <div class="customer-login-content">
    <div v-if="error" class="error-message-container">
      <div>{{ error.title }}</div>
      <div>{{ error.message }}</div>
    </div>
    <h4 v-else class="mb-5 font-normal text-xl font-grey">
      Log in to your account
    </h4>
    <hr class="line-hr w-full mb-5" />
    <p class="mb-8 text-center">
      Enter the code we just sent to your mobile {{ phoneNumber }}
    </p>
    <div class="phone-num-container flex justify-center flex-col items-start">
      <CodeInput :loading="false" class="input" v-on:change="onNumberChange" />
      <p class="ml-6">
        Did'nt get it?
        <span class="link-button cursor-pointer" @click="resend"
          ><u class="light-blue">Resend code</u>
          <span class="text-gray-font">
            {{ `${countDownShown ? `(Expires in ${countDown} seconds)` : ""}` }}
          </span>
        </span>
      </p>
    </div>

    <vs-button @click="login" class="m-2" :disabled="otpCode.length < 6"
      >Log In</vs-button
    >
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";

export default {
  components: {
    CodeInput
  },
  props: {
    otpCode: { type: String },
    phoneNumber: { type: String },
    onNumberChange: { type: Function },
    resend: { type: Function },
    submitOtp: { type: Function }
  },
  data() {
    return {
      error: "",
      countDown: 180,
      countDownShown: false
    };
  },
  methods: {
    async login() {
      this.error = await this.submitOtp();
      
    }
  }
};
</script>
