<template>
  <div class="flex vx-row no-gutter  items-center justify-center">
    <div class="vx-col  sm:m-0 m-4">
      <div class="flex justify-center mb-8">
        <img v-if="appName === 'FlipPay'" src="@/assets/images/logo/flippay-logo.svg" width="auto" height="48px" alt="Flippay" class="mx-auto"/>
        <img v-else-if="appName === 'RelloPay'" src="@/assets/images/logo/rellopay-logo.svg" width="auto" height="48px" alt="Rellopay" class="mx-auto"/>
      </div>
      <vx-card class="customer-login-card">
        <div class="customer-login-container pt-0 mb-0">
          <div v-if="index === 1" class="w-full">
            <login :loginOnboarding="loginOnboarding" :forgotPassword="forgotPasswordButton"/>
          </div>
          <div v-if="index===2">
            <forgot-password :forgotPassword="sendForgotPasswordEmail" :backToLogin="resetEverything"/>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CodeInput from "vue-verification-code-input";
import Login from "./Login.vue";
import OTP from "./OTP.vue";
import ForgotPassword from "./ForgotPassword.vue";

export default {
  components: {
    CodeInput,
    Login,
    OTP,
    ForgotPassword
  },
  data() {
    return {
      logo: require("@/assets/images/logo/flippay-logo.svg"),
      appName: process.env.VUE_APP_NAME ||"FlipPay",
      serverUrl: process.env.VUE_APP_API_URL,
      index: 1,
      id: "",
      merchantId: "",
    };
  },
  methods: {
    ...mapActions("onboardMerchant", [
      "loginOnboardingUser",
      "forgotPassword"
    ]),
    formattedUrl(url) {
      if (!url) return "";
      return url.includes("http") ? url : `https://${url}`;
    },
    async loginOnboarding(email, password) {
      this.$vs.loading();
      return await this.loginOnboardingUser({
        email,
        password
      })
        .then(res => {
          this.$vs.loading.close();
          this.$router.push({
            name: "onboarding-form",
            params: { introducerRequestedId: res.data.data._id }
          });
          return null;
        })
        .catch(e => {
          this.$vs.loading.close();
          return e.response.data;
        });
    },
    forgotPasswordButton() {
      this.index = 2;
    },
    async sendForgotPasswordEmail(email) {
      this.$vs.loading();
      return await this.forgotPassword({
        email
      })
        .then(res => {
          this.$vs.loading.close();

          res.data.status = "success";
          return res.data;
        })
        .catch(e => {
          this.$vs.loading.close();

          e.response.data.status = "failure";
          return e.response.data;
        });
    },
    resetEverything() {
      this.index = 1;
      this.id = "";
    }
  },
  created() {
    // this.merchantId = this.$route.params.merchantId;
  }
};
</script>
